import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  exhaustMap,
} from 'rxjs/operators';
import { ApiService } from '@shared/services/api.service';

import * as AccountActions from './accounts.actions';
import * as StatusActions from '../status/status.actions';
import { Account } from '@shared/models/account';
import { of } from 'rxjs';
import {
  BandwidthRequest,
  BandwidthResponse,
  ResourceRequest,
  ResourceResponse,
  TrafficLogsExportsRequest,
  TrafficLogsExportsResponse,
  TrafficLogsRequest,
  TrafficLogsResponse,
} from '@shared/models/api-response/api-response-analytics.model';
import {
  RouteRequest,
  RouteResponse,
  SSLVPNRequest,
  SSLVPNResponse,
} from '@shared/models/api-response/api-response-remote.model';
import {
  NetworkOverviewRequest,
  NetworkOverviewResponse,
} from '@shared/models/api-response/api-response-connection.model';
import { HttpParams } from '@angular/common/http';
import {
  FirewallAPIResponse,
  URLFilterAPIResponse,
} from '@shared/models/api-response/api-response-firewall.model';
import { FirewallType } from '@shared/models/firewall.model';

@Injectable()
export class AccountEffects {
  fetchAccounts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.FETCH_ACCOUNTS),
      mergeMap(() =>
        this.apiService.get(`/accounts/all`).pipe(
          map((accounts: Account[]) =>
            AccountActions.setAccounts({ accounts })
          ),
          catchError((err) => of(StatusActions.handleError(err)))
        )
      )
    );
  });

  fetchAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.FETCH_ACCOUNT),
      mergeMap((action: { type: string; id: string }) =>
        this.apiService.get(`/accounts/${action.id}`).pipe(
          map((accounts: Account[]) =>
            AccountActions.setAccounts({ accounts })
          ),
          catchError((err) => of(StatusActions.handleError(err)))
        )
      )
    );
  });

  fetchMyAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.FETCH_MY_ACCOUNT),
      mergeMap(() =>
        this.apiService.get(`/accounts/me`).pipe(
          map((accounts: Account[]) =>
            AccountActions.setAccounts({ accounts })
          ),
          catchError((err) => of(StatusActions.handleError(err)))
        )
      )
    );
  });

  fetchPartnerAccounts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.FETCH_PARTNER_ACCOUNTS),
      mergeMap(() =>
        this.apiService.get(`/partners/accounts`).pipe(
          map((accounts) => AccountActions.setAccounts({ accounts })),
          catchError((err) => of(StatusActions.handleError(err)))
        )
      )
    );
  });

  fetchBandwidthData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.FETCH_BANDWIDTH_DATA),
      mergeMap((action: BandwidthRequest) => {
        let url = `/analytics/bandwidth/${action.accountId}`;

        if (action.start && action.end) {
          url += `/${action.start.toISO()}/${action.end.toISO()}`;
        }
        url += '?measurement=max';

        if (action.groupBy) {
          url += `&groupBy=${action.groupBy}`;
        }
        if (action.interval) {
          url += `&interval=${action.interval}`;
        }

        return this.apiService.get(url).pipe(
          catchError((error) =>
            this.handleError(error, {
              accountId: action.accountId,
              type: 'bandwidth',
            })
          )
        );
      }),
      map((bandwidthData: BandwidthResponse) =>
        AccountActions.setBandwidthData({ bandwidthData })
      )
    );
  });

  fetchResourceData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.FETCH_RESOURCE_DATA),
      mergeMap((action: ResourceRequest) => {
        const dateFilter = action.interval;
        const accountId = action.accountId;
        let url: string;
        if (!dateFilter) {
          url = `/analytics/resource-fa/${action.resource}/${accountId}/${action.start}/${action.end}`;
        } else {
          url = `/analytics/resource-fa/filter/${action.resource}/${accountId}/${dateFilter}`;
        }
        return this.apiService.get(url).pipe(
          catchError((error) =>
            this.handleError(error, {
              accountId: action.accountId,
              type: action.resource,
            })
          )
        );
      }),
      map((resourceData: ResourceResponse) =>
        AccountActions.setResourceData({ resourceData })
      )
    );
  });

  fetchTrafficLogsExports$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.FETCH_TRAFFIC_LOGS_EXPORTS),
      mergeMap((action: TrafficLogsExportsRequest) => {
        const accountId = action.accountId;
        const url = `/analytics/logs/all?accountId=${accountId}`;
        return this.apiService.get(url).pipe(
          catchError((error) =>
            this.handleError(error, {
              accountId: action.accountId,
            })
          )
        );
      }),
      map((trafficLogsExports: TrafficLogsExportsResponse) =>
        AccountActions.setTrafficLogsExports({ trafficLogsExports })
      )
    );
  });

  fetchRouteData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.FETCH_ROUTE_DATA),
      switchMap((action: RouteRequest) => {
        const url = '/remote/routing';
        let params = new HttpParams()
          .set('accountId', action.accountId)
          .set('cacheBreak', action.cacheBreak);

        return this.apiService.get(url, params).pipe(
          catchError((error) =>
            this.handleError(error, {
              accountId: action.accountId,
              type: 'route',
            })
          )
        );
      }),
      map((routeData: RouteResponse) =>
        AccountActions.setRouteData({ routeData })
      )
    );
  });

  fetchSSLVPNData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.FETCH_SSL_VPN_DATA),
      switchMap((action: SSLVPNRequest) => {
        const url = '/remote/ssl';
        let params = new HttpParams()
          .set('accountId', action.accountId)
          .set('cacheBreak', action.cacheBreak);

        return this.apiService.get(url, params).pipe(
          catchError((error) =>
            this.handleError(error, {
              accountId: action.accountId,
              type: 'ssl-vpn',
            })
          )
        );
      }),
      map((sslVPNData: SSLVPNResponse) =>
        AccountActions.setSSLVPNData({ sslVPNData })
      )
    );
  });

  fetchNetworkOverview$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.FETCH_NETWORK_OVERVIEW),
      mergeMap((action: NetworkOverviewRequest) => {
        const accountId: string = action.accountId;
        const cacheBreak: boolean = action.cacheBreak || false;
        let params = new HttpParams().set('accountId', accountId);
        if (cacheBreak) params = params.set('cacheBreak', cacheBreak);
        return this.apiService.get(
          '/connections/dashboard/network-overview-v2',
          params
        );
      }),
      map((networkOverviewData: NetworkOverviewResponse) =>
        AccountActions.setNetworkOverview({ networkOverviewData })
      )
    );
  });

  fetchFirewallData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.FETCH_FIREWALL_DATA),
      mergeMap(
        (action: {
          type: string;
          accountId: string;
          firewallType: FirewallType;
        }) => {
          const accountId: string = action.accountId;
          const firewallType: string = action.firewallType;
          const params = new HttpParams()
            .set('accountId', accountId)
            .set('firewallType', firewallType);

          return this.apiService.get(`/firewall/rules`, params).pipe(
            catchError((error) =>
              this.handleError(error, {
                accountId,
              })
            )
          );
        }
      ),
      map((firewallData: FirewallAPIResponse) =>
        AccountActions.setFirewallData({
          firewallData,
          firewallType: firewallData.meta.firewallType,
        })
      )
    );
  });

  fetchFirewallURLFilters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.FETCH_FIREWALL_URLFILTERS),
      switchMap(
        (action: {
          type: string;
          accountId: string;
          filterId: number;
          firewallType: FirewallType;
        }) => {
          const params = new HttpParams().set('accountId', action.accountId);
          return this.apiService.get(
            `/firewall/urlfilters/${action.filterId}?firewallType=${action.firewallType}`,
            params
          );
        }
      ),
      map((filterData: URLFilterAPIResponse) =>
        AccountActions.setFirewallURLFilters({ filterData })
      )
    );
  });

  fetchTickets$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.FETCH_TICKETS),
      exhaustMap(
        (action: {
          type: string;
          accountId: string;
          ticketType: string;
          cacheBreak: boolean;
        }) => {
          const ticketParams: any = {
            accountId: action.accountId,
            type: action.ticketType,
            cacheBreak: action.cacheBreak ?? false
          };
          // return API request data
          return this.apiService.get('/support/tickets', ticketParams).pipe(
            map((apiResponse: any) => {
              // setTickets from API response data to store
              return AccountActions.setTickets(apiResponse);
            })
          );
        }
      )
    );
  });

  fetchReportList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.FETCH_REPORT_LIST),
      exhaustMap((action: { type: string; accountId: string }) => {
        const ticketParams: any = {
          accountId: action.accountId,
        };
        // return API request data
        return this.apiService.get('/support/reports/list', ticketParams).pipe(
          map((apiResponse: any) => {
            // setTickets from API response data to store
            return AccountActions.setReportList(apiResponse);
          })
        );
      })
    );
  });

  fetchReports$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.FETCH_REPORTS),
      exhaustMap((action: { type: string; accountId: string }) => {
        const ticketParams: any = {
          accountId: action.accountId,
        };
        // return API request data
        return this.apiService.get('/support/reports/all', ticketParams).pipe(
          map((apiResponse: any) => {
            // setTickets from API response data to store
            return AccountActions.setReports(apiResponse);
          })
        );
      })
    );
  });

  flushCache$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.FLUSH_CACHE),
      map((action: { type: string; accountId: string }) => action.accountId),
      mergeMap((accountId: string) =>
        this.apiService.post(`/accounts/cache-flush/${accountId}`).pipe(
          map(() => AccountActions.completeFlush()),
          catchError((err) => of(StatusActions.handleError(err)))
        )
      )
    );
  });

  constructor(private apiService: ApiService, private actions$: Actions) {}

  // TODO: [SIL-201] Move this out of the effects and into a service / reducer
  private handleError = (error: any, metaData: any) => {
    let errorMessage: string;

    if (error?.error?.data?.message) {
      errorMessage = error.error.data.message;
    } else {
      errorMessage = 'Something went wrong. Please try again later...';
    }
    const errorResponse = {
      error: errorMessage,
      meta: metaData,
    };
    return of(AccountActions.accountsError(errorResponse));
  };
}
