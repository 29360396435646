import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions } from '@ngrx/effects';
import { Observable, of, Subscriber } from 'rxjs';
import { take, switchMap, skipWhile, filter, mergeMap } from 'rxjs/operators';

/* Store */
import * as AccountsActions from '@store/accounts/accounts.actions';
import * as fromAccounts from '@store/accounts/accounts.selectors';

/* Services */
import { ApiService } from '@shared/services/api.service';

/* Models */
import {
  IPSECVPNRequest,
  RouteRequest,
  SSLVPNRequest,
  CreateSSLUserRequest,
  ChangeVPNPasswordRequest,
} from '@shared/models/api-response/api-response-remote.model';
import { Route } from '@shared/models/route';
import {
  NetworkOverviewData,
  NetworkOverviewRequest,
} from '@shared/models/api-response/api-response-connection.model';
import { IPSECVPN } from './models/ipsec-vpn.model';
import { PasswordResetRequest, SSLVPN } from './models/ssl-vpn.model';
import { ValidationError } from '@shared/models/error';
import { AsyncLambdaResponse } from '@shared/models/api-response';

@Injectable({
  providedIn: 'root',
})
export class ConnectService {
  constructor(
    private store: Store,
    private apiService: ApiService,
    private actions$: Actions
  ) {}

  public getRoutes({
    accountId,
    cacheBreak = false,
  }: RouteRequest): Observable<Route[]> {
    return this.store
      .select(fromAccounts.selectRouteData({ accountId, cacheBreak }))
      .pipe(
        take(1),
        switchMap((routeData) => {
          if (!routeData || cacheBreak === true) {
            this.store.dispatch(
              AccountsActions.fetchRouteData({ accountId, cacheBreak })
            );
            return this.actions$.pipe(
              // Ignore null
              skipWhile((val) => val === null),
              // only when action is SET_ROUTE_DATA and updated routes are in this account
              filter(
                (action: any) =>
                  (action.type === AccountsActions.SET_ROUTE_DATA &&
                    action.routeData.meta.accountId === accountId) ||
                  action.routeData?.error
              ),
              mergeMap((data) => {
                if (data.routeData.error) {
                  throw data.routeData.error;
                } else {
                  return this.store.select(
                    fromAccounts.selectRouteData({ accountId, cacheBreak })
                  );
                }
              })
            );
          } else {
            return of(routeData);
          }
        })
      );
  }

  public getRoutesAsCSV({ accountId }: RouteRequest): Observable<any> {
    const url = `/remote/routing?accountId=${accountId}&format=csv`;
    return this.apiService.get(url);
  }

  public getNetworkOverview(
    request: NetworkOverviewRequest
  ): Observable<NetworkOverviewData> {
    return this.store.select(fromAccounts.selectNetworkOverview(request)).pipe(
      take(1),
      switchMap((networkOverviewData: NetworkOverviewData) => {
        if (!networkOverviewData || request.cacheBreak === true) {
          this.store.dispatch(AccountsActions.fetchNetworkOverview(request));
          return this.actions$.pipe(
            // Ignore null
            skipWhile((val) => val === null),
            // only when action is SET_NETWORK_OVERVIEW and updated network are in this account
            filter(
              (action: any) =>
                action.type === AccountsActions.SET_NETWORK_OVERVIEW &&
                action.networkOverviewData.meta.accountId === request.accountId
            ),
            mergeMap((data) => {
              if (data.networkOverviewData.error) {
                throw data.networkOverviewData.error;
              } else {
                return this.store.select(
                  fromAccounts.selectNetworkOverview(request)
                );
              }
            })
          );
        } else {
          return of(networkOverviewData);
        }
      })
    );
  }

  public getNetworkOverviewAsCSV(accountId: string): Observable<any> {
    const url = `/connections/dashboard/network-overview-v2?accountId=${accountId}&csv=true`;
    return this.apiService.get(url);
  }

  public getSSLVPNs({
    accountId,
    cacheBreak = false,
  }: SSLVPNRequest): Observable<SSLVPN[] | undefined> {
    return this.store
      .select(fromAccounts.selectSSLVPNData({ accountId, cacheBreak }))
      .pipe(
        take(1),
        switchMap((sslVPNData) => {
          if (!sslVPNData || cacheBreak === true) {
            this.store.dispatch(
              AccountsActions.fetchSSLVPNData({ accountId, cacheBreak })
            );
            return this.actions$.pipe(
              // Ignore null
              skipWhile((val) => val === null),
              // only when action is SET_SSL_VPN_DATA and updated routes are in this account
              filter(
                (action: any) =>
                  (action.type === AccountsActions.SET_SSL_VPN_DATA &&
                    action.sslVPNData.meta.accountId === accountId) ||
                  action.sslVPNData?.error
              ),
              mergeMap((data) => {
                if (data.sslVPNData.error) {
                  throw data.sslVPNData.error;
                } else {
                  return this.store.select(
                    fromAccounts.selectSSLVPNData({ accountId, cacheBreak })
                  );
                }
              })
            );
          } else {
            return of(sslVPNData);
          }
        })
      );
  }

  public getIPSECVPNs({ accountId }: IPSECVPNRequest): Observable<IPSECVPN[]> {
    return new Observable((observer) => {
      if (!accountId) {
        observer.error('You must pass an accountId');
        observer.complete();
      }
      this.apiService.get(`/remote/ipsec?accountId=${accountId}`).subscribe({
        next: (response) => {
          if (response && response.data) {
            observer.next(response.data);
            observer.complete();
          } else if (response && response.code) {
            observer.error(response.message);
            observer.complete();
          } else {
            observer.error();
            observer.complete();
          }
        },
        error: () => {
          // Error response can be found in e.error
          observer.error();
          observer.complete();
        },
      });
    });
  }

  public createToken(request: PasswordResetRequest): Observable<any> {
    return new Observable((observer) => {
      this.apiService.post(`/vpn-admin/token`, request).subscribe({
        next: () => {
          observer.next();
          observer.complete();
        },
        error: () => {
          // Error response can be found in e.error
          observer.error();
          observer.complete();
        },
      });
    });
  }

  public createSSLUser(
    request: CreateSSLUserRequest,
    accountId: string
  ): Observable<any> {
    return new Observable((observer) => {
      const validation = this.validate(request);

      if (validation !== true) {
        observer.error(validation);
        observer.complete();
        return;
      }
      this.apiService
        .post(`/remote/user?accountId=${accountId}`, request)
        .subscribe({
          next: (response) => {
            if (response.data.username) {
              observer.next(response.data.username);
              observer.complete();
            } else {
              observer.error();
              observer.complete();
            }
          },
          error: (err) => {
            // Error response can be found in e.error
            observer.error(err);
            observer.complete();
          },
        });
    });
  }

  public validate(request: CreateSSLUserRequest): true | ValidationError {
    let validation = true;
    const errors: ValidationError = {
      firstname: [],
      lastname: [],
      email: [],
    };

    if (!request.firstname || request.firstname === '') {
      errors.firstname.push('Please enter a first name');
      validation = false;
    }
    if (!request.lastname || request.lastname === '') {
      errors.lastname.push('Please enter a last name');
      validation = false;
    }
    if (!request.email || request.email === '') {
      errors.email.push('Please enter an email');
      validation = false;
    } else if (/.*@.*\..*/.test(request.email) === false) {
      // tests email for *@*.*
      errors.email.push('Your email must be in the right format');
      validation = false;
    }

    return validation === true ? true : errors;
  }

  public bulkUserCreate(request: any, accountId: string): Observable<any> {
    return new Observable((observer) => {
      this.apiService
        .post(`/remote/bulk-user?accountId=${accountId}`, request)
        .subscribe({
          next: (response: AsyncLambdaResponse) => {
            this.asyncComplete(observer, response);
          },
          error: (err) => {
            observer.error(err);
            observer.complete();
          },
        });
    });
  }

  public bulkUserDelete(users: SSLVPN[], accountId: string): Observable<any> {
    return new Observable((observer) => {
      this.apiService
        .deleteWithBody(
          `/remote/bulk-user?accountId=${accountId}`,
          JSON.stringify(users)
        )
        .subscribe({
          next: (response: AsyncLambdaResponse) => {
            this.asyncComplete(observer, response);
          },
          error: (err) => {
            observer.error(err);
            observer.complete();
          },
        });
    });
  }

  public bulkUserResetTokens(
    users: SSLVPN[],
    accountId: string
  ): Observable<any> {
    return new Observable((observer) => {
      this.apiService
        .put(
          `/remote/bulk-user-reset?accountId=${accountId}`,
          JSON.stringify(users)
        )
        .subscribe({
          next: (response: AsyncLambdaResponse) => {
            this.asyncComplete(observer, response);
          },
          error: (err) => {
            observer.error(err);
            observer.complete();
          },
        });
    });
  }

  public changeVPNPassword(
    request: ChangeVPNPasswordRequest,
    accountId: string
  ): Observable<any> {
    return new Observable((observer) => {
      this.apiService
        .put(`/remote/vpn-password?accountId=${accountId}`, request)
        .subscribe({
          next: (response) => {
            if (response.data.username) {
              observer.next(response.data.username);
              observer.complete();
            } else {
              observer.error();
              observer.complete();
            }
          },
          error: (err) => {
            // Error response can be found in e.error
            observer.error(err);
            observer.complete();
          },
        });
    });
  }

  public resetVPNTunnel(ipsec: IPSECVPN, accountId: string) {
    return this.apiService.put(
      `/remote/reset-vpn-tunnel?accountId=${accountId}`,
      ipsec
    );
  }

  private asyncComplete(
    observer: Subscriber<null>,
    response: AsyncLambdaResponse
  ) {
    if (response.data.asyncLaunched) {
      observer.next();
      observer.complete();
    } else {
      observer.error();
      observer.complete();
    }
  }
}
